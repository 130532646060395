<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 48 1"
    fill="none"
  >
    <line y1="0.5" x2="48" y2="0.5" stroke="black" />
  </svg>
</template>

<script>
export default {
  name: "LineInTheMiddle",
  components: {},
};
</script>

<style>
</style>