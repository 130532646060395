<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 64 64"
    class="bg-yellow-400"
    fill="none"
  >
    <path
      d="M56 32V12C56 10.9391 55.5786 9.92172 54.8284 9.17157C54.0783 8.42143 53.0609 8 52 8H12C10.9391 8 9.92172 8.42143 9.17157 9.17157C8.42143 9.92172 8 10.9391 8 12V52C8 53.0609 8.42143 54.0783 9.17157 54.8284C9.92172 55.5786 10.9391 56 12 56H32"
      stroke="black"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M42.668 50.6667C47.0862 50.6667 50.668 47.085 50.668 42.6667C50.668 38.2485 47.0862 34.6667 42.668 34.6667C38.2497 34.6667 34.668 38.2485 34.668 42.6667C34.668 47.085 38.2497 50.6667 42.668 50.6667Z"
      stroke="black"
      stroke-width="2"
    />
    <path
      d="M49.332 48L55.9987 53.3333"
      stroke="black"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M18.668 21.3333H45.3346"
      stroke="black"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M18.668 32H29.3346"
      stroke="black"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <rect x="0.5" y="0.5" width="63" height="63" stroke="black" />
  </svg>
</template>

<script>
export default {
  name: "SearchIcon",
  components: {},
};
</script>

<style>
</style>