<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 64 64"
    class="bg-orange-400"
    fill="none"
  >
    <path
      d="M17.1124 20.0001C18.0013 15.5556 22.0013 12.2223 26.668 12.2223C30.0013 12.2223 32.668 13.7779 34.4457 16.2223L38.2235 11.7779C35.5569 8.66675 31.3346 6.66675 26.668 6.66675C18.8902 6.66675 12.4457 12.4445 11.3346 20.0001H6.66797L14.4457 28.889L22.2235 20.0001H17.1124ZM38.8902 15.5556L31.1124 24.4445H36.2235C36.2235 28.889 32.4457 32.2223 27.7791 32.2223C24.4457 32.2223 20.668 30.6668 18.8902 28.2223L15.1124 32.4445C17.7791 35.7779 23.1124 37.7779 27.7791 37.7779C35.5569 37.7779 40.8902 32.0001 42.0013 24.4445H46.668L38.8902 15.5556Z"
      fill="black"
    />
    <path
      d="M42.668 50.6667C47.0862 50.6667 50.668 47.085 50.668 42.6667C50.668 38.2485 47.0862 34.6667 42.668 34.6667C38.2497 34.6667 34.668 38.2485 34.668 42.6667C34.668 47.085 38.2497 50.6667 42.668 50.6667Z"
      stroke="black"
      stroke-width="2"
    />
    <path
      d="M49.332 48L55.9987 53.3333"
      stroke="black"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <rect x="0.5" y="0.5" width="63" height="63" stroke="black" />
  </svg>
</template>

<script>
export default {
  name: "SearchRefineIcon",
  components: {},
};
</script>

<style>
</style>