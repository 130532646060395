<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 36 30"
    fill="none"
  >
    <path d="M18 30L0.679489 0L35.3205 0L18 30Z" fill="black" />
  </svg>
</template>

<script>
export default {
  name: "TriangleBlack",
  components: {},
};
</script>

<style>
</style>