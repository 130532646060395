<template>
  <h1 class="text-xl font-semibold">
    <slot> </slot>
  </h1>
</template>

<script>
export default {
  name: "SectionTitle",
  components: {},
};
</script>

<style>
</style>