<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1 48"
    fill="none"
  >
    <line x1="0.5" y2="48" x2="0.5" stroke="black" />
  </svg>
</template>

<script>
export default {
  name: "LineInTheMiddleVertical",
  components: {},
};
</script>

<style>
</style>