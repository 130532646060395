<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 64 64"
    class="bg-yellow-400"
    fill="none"
  >
    <path
      d="M25.332 14.6667L41.332 30.6667L25.332 46.6667"
      stroke="black"
      stroke-width="3"
    />
    <path
      d="M31.3828 51.6602L31.4238 52.3926C31.8926 51.8262 32.5078 51.543 33.2695 51.543C34.5898 51.543 35.2617 52.2988 35.2852 53.8105V58H33.8613V53.8926C33.8613 53.4902 33.7734 53.1934 33.5977 53.002C33.4258 52.8066 33.1426 52.709 32.748 52.709C32.1738 52.709 31.7461 52.9688 31.4648 53.4883V58H30.041V51.6602H31.3828ZM39.5332 58.1172C38.6309 58.1172 37.8984 57.834 37.3359 57.2676C36.7773 56.6973 36.498 55.9395 36.498 54.9941V54.8184C36.498 54.1855 36.6191 53.6211 36.8613 53.125C37.1074 52.625 37.4512 52.2363 37.8926 51.959C38.334 51.6816 38.8262 51.543 39.3691 51.543C40.2324 51.543 40.8984 51.8184 41.3672 52.3691C41.8398 52.9199 42.0762 53.6992 42.0762 54.707V55.2812H37.9336C37.9766 55.8047 38.1504 56.2188 38.4551 56.5234C38.7637 56.8281 39.1504 56.9805 39.6152 56.9805C40.2676 56.9805 40.7988 56.7168 41.209 56.1895L41.9766 56.9219C41.7227 57.3008 41.3828 57.5957 40.957 57.8066C40.5352 58.0137 40.0605 58.1172 39.5332 58.1172ZM39.3633 52.6855C38.9727 52.6855 38.6562 52.8223 38.4141 53.0957C38.1758 53.3691 38.0234 53.75 37.957 54.2383H40.6699V54.1328C40.6387 53.6562 40.5117 53.2969 40.2891 53.0547C40.0664 52.8086 39.7578 52.6855 39.3633 52.6855ZM45.4629 53.7461L46.6699 51.6602H48.252L46.3125 54.7832L48.3164 58H46.7461L45.4805 55.832L44.2207 58H42.6387L44.6426 54.7832L42.709 51.6602H44.2793L45.4629 53.7461ZM51.0059 50.1191V51.6602H52.125V52.7148H51.0059V56.2539C51.0059 56.4961 51.0527 56.6719 51.1465 56.7812C51.2441 56.8867 51.416 56.9395 51.6621 56.9395C51.8262 56.9395 51.9922 56.9199 52.1602 56.8809V57.9824C51.8359 58.0723 51.5234 58.1172 51.2227 58.1172C50.1289 58.1172 49.582 57.5137 49.582 56.3066V52.7148H48.5391V51.6602H49.582V50.1191H51.0059Z"
      fill="black"
    />
    <rect x="0.5" y="0.5" width="63" height="63" stroke="black" />
  </svg>
</template>

<script>
export default {
  name: "NextPage",
  components: {},
};
</script>

<style>
</style>