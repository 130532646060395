<template>
  <!-- <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 48 48"
    fill="none"
  >
    <path
      d="M24 3C12.4031 3 3 12.4031 3 24C3 35.5969 12.4031 45 24 45C35.5969 45 45 35.5969 45 24C45 12.4031 35.5969 3 24 3ZM24 41.4375C14.3719 41.4375 6.5625 33.6281 6.5625 24C6.5625 19.8281 8.02969 15.9938 10.4766 12.9938L35.0062 37.5234C32.0062 39.9703 28.1719 41.4375 24 41.4375ZM37.5234 35.0062L12.9938 10.4766C15.9938 8.02969 19.8281 6.5625 24 6.5625C33.6281 6.5625 41.4375 14.3719 41.4375 24C41.4375 28.1719 39.9703 32.0062 37.5234 35.0062Z"
      fill="black"
    />
    <rect x="0.5" y="0.5" width="47" height="47" stroke="black" />
  </svg> -->

  <svg viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M34 14L31.18 16.82L36.34 22H16V26H36.34L31.18 31.16L34 34L44 24L34 14ZM8 10H24V6H8C5.8 6 4 7.8 4 10V38C4 40.2 5.8 42 8 42H24V38H8V10Z"
      fill="black"
    />
    <rect x="0.5" y="0.5" width="47" height="47" stroke="black" />
  </svg>
</template>

<script>
export default {
  name: "EmptyIcon",
  components: {},
};
</script>

<style>
</style>